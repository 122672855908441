import {
  container,
  title,
  main,
  mainRaised,
  description,
  section,
  mlAuto,
  mrAuto,
} from "assets/jss/material-kit-pro-react.jsx";

const underWritingStyle = {
  title,
  main,
  mainRaised,
  section,
  container,
  description,
  mlAuto,
  mrAuto,
  textCenter: {
    textAlign: `center`,
  },
  containerBottom: {
    paddingBottom: `25px`,
  },
};

export default underWritingStyle;
