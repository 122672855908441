import React from "react";
import { graphql } from "gatsby";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import Layout from "ps-components/Layout/Layout";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import underWritingStyle from "ps-assets/jss/underWritingPageStyle";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
const UnderwritingPhilosophy = ({ classes, data }) => {
  return (
    <Layout
      image2={data.allStrapiImages.edges[0].node.content.childImageSharp.fluid}
      small={true}
    >
      <Helmet key="helmetTags">
    <title>Under Writing Philosophy | StarHealth.in</title>
  </Helmet>
  {process.env.ENABLE_HELMET == `true` && (
    <Helmet key="helmetTags">
      <link
        rel="canonical"
        href={url + `Under_Writing_philosophy_01-10-2020`}
      />
      <meta name="title" content="Under Writing Pholosophy | StarHealth.in" />
      <meta name="twitter:title" content="Under Writing Pholosophy | StarHealth.in" />
      <meta
        name="description"
        content="Underwriting philosophy for offering Insurance coverage to persons with Disability and Mental Illness"
      />
      <meta
        name="keywords"
        content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
      />
      <meta property="og:title" content="Under Writing Pholosophy | StarHealth.in" />
      <meta property="og:description" content="Underwriting philosophy for offering Insurance coverage to persons with Disability and Mental Illness" />
      <meta property="og:url" content={url + `Under_Writing_philosophy_01-10-2020`} />
      <meta name="Under Writing Pholosophy | StarHealth.in" />
      <meta name="twitter:description" content="Underwriting philosophy for offering Insurance coverage to persons with Disability and Mental Illness" />
      <meta property="twitter:url" content={url + `Under_Writing_philosophy_01-10-2020`} />
    </Helmet>
  )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: `100px` }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer className={classes.containerBottom}>
              <GridItem
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter
                )}
              >
                <h3 className={classes.title} md={12} sm={12} xs={12}>
                  Underwriting philosophy for offering Insurance coverage to
                  persons with Disability and Mental Illness
                </h3>
              </GridItem>
              <GridItem
                md={11}
                className={classNames(classes.mlAuto, classes.mrAuto)}
              >
                <h4 className={classes.title}>Introduction:</h4>
                <p>
                  Following is broad underwriting philosophy to offer health
                  insurance coverage to Person with Disabilities and Persons
                  with Mental Illness. To make provision for medical insurance
                  for treatment of mental illness on the same basis as is
                  available for treatment of physical illness. The philosophy is
                  made with large principle of ensuring that there is no
                  discrimination bias and adhering to the provisions under the
                  Mental Health Act 2017.
                  <br />
                  <br />
                  “Mental Illness” as defined in DSM5 and ICD10 Refers
                  collectively to all diagnosable mental disorders health
                  conditions involving
                </p>
                <ul>
                  <li>
                    <p>Significant changes in thinking, emotion and behavior</p>
                  </li>
                  <li>
                    <p>
                      Distress or problems functioning in social, work or family
                      activities
                    </p>
                  </li>
                </ul>
                <p>Types of Disabilities:</p>
                <ul>
                  <li>
                    <p>
                      Physical disability- disability pertaining to person’s
                      mobility- Level of disability
                    </p>
                  </li>
                  <li>
                    <p>Intellectual or Learning Disabilities</p>
                  </li>
                  <li>
                    <p>Psychiatric disabilities</p>
                  </li>
                  <li>
                    <p>Neurological disabilities</p>
                  </li>
                  <li>
                    <p>Visual or hearing impairments</p>
                  </li>
                </ul>
                <p>
                  The following information will be considered while
                  underwriting the proposal for health insurance with disability
                  and/or mental illness. The underwriting practice for these
                  illnesses is in line with the underwriting practices followed
                  for any other medical conditions (eg: Heart disease, lung
                  disease etc.)
                  <br />
                  <br />
                  Below information will assist the Underwriter in fair risk
                  assessment and unbiased underwriting decision on the proposal
                </p>
                <ul>
                  <li>
                    <p>
                      Exact medical diagnosis, cause, and duration of illness,
                      and its progress
                    </p>
                  </li>
                  <li>
                    <p>
                      Treatment modalities since inception of the medical
                      condition, including previous medical conditions.
                    </p>
                  </li>
                </ul>
                <p>
                  Proposal would be evaluated based upon board approved
                  underwriting guidelines for making an objective decision.
                  <br />
                  <br />
                  In cases, where objective criteria are not defined in the
                  guidelines, such proposal are underwritten basis medical
                  assessment and case merits specifically without discrimination
                  or bias by medical panel of the company.
                  <br />
                  <br />
                  Mental health disorders are syndromes characterized by
                  significant disturbance in a person’s cognition, emotional
                  regulation and behavior resulting from a disturbance in
                  psychological or biological or developmental processes that
                  reinforce mental functioning.
                  <br />
                  <br />
                  Determinants for severity of the condition are multifactorial
                </p>
                <ul>
                  <li>
                    <p>Age of onset</p>
                  </li>
                  <li>
                    <p>Duration of the treatment</p>
                  </li>
                  <li>
                    <p>Treatment response</p>
                  </li>
                  <li>
                    <p>Co - morbid conditions</p>
                  </li>
                </ul>
                <p>
                  Risk factors that are considered for mental health disorders
                  are
                </p>
                <ul>
                  <li>
                    <p>Previous medical and family history</p>
                  </li>
                  <li>
                    <p>Duration of illness</p>
                  </li>
                  <li>
                    <p>Co morbidity</p>
                  </li>
                  <li>
                    <p>Dual diagnosis</p>
                  </li>
                  <li>
                    <p>Substance abuse</p>
                  </li>
                  <li>
                    <p>Compliance with treatment</p>
                  </li>
                </ul>
                <p>
                  Actual Selection criteria for each condition depends on
                  variability of presentation
                </p>
              </GridItem>
              <GridItem
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter
                )}
              >
                <h3 className={classes.title} md={12} sm={12} xs={12}>
                  Underwriting philosophy for offering Insurance coverage to
                  persons affected with HIV/AIDS
                </h3>
              </GridItem>
              <GridItem
                md={11}
                className={classNames(classes.mlAuto, classes.mrAuto)}
              >
                <p>
                  The philosophy is made with large principle of ensuring that
                  there is no discrimination bias and adhering to the provisions
                  of HIV and AIDS Prevention and control Act, 2017.
                  <br />
                  <br />
                  To ensure fair underwriting decision, the following
                  information will be considered while underwriting the proposal
                  for health insurance from applicants affected by HIV/AIDS.
                </p>
                <ul>
                  <li>
                    <p>Detail record of treatment and medical history.</p>
                  </li>
                  <li>
                    <p>Confirmation of diagnosis with Stage of Disease</p>
                  </li>
                  <li>
                    <p>
                      Medical examination and blood tests as required under the
                      product and underwriting guidelines
                    </p>
                  </li>
                  <li>
                    <p>Details of past medical history</p>
                  </li>
                  <li>
                    <p>Details of co-morbidities and Systemic complications</p>
                  </li>
                </ul>
                <p>
                  The approach is to ensure that every application is
                  underwritten on merit based on the guidelines under the
                  underwriting policy and there is no discrimination in granting
                  Health insurance coverage to people affected by HIV/AIDS. The
                  acceptance of risk will be based on objective underwriting
                  criteria and basis the risk represented, ensuring that there
                  is no moral hazard. While the evidence based underwriting
                  guidelines of the company, which is approved by the board of
                  the company will form basis for making an objective decision.
                </p>
              </GridItem>
              
              <GridItem
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter
                )}
              >
                <h3 className={classes.title} md={12} sm={12} xs={12}>
                Underwriting philosophy of offering Health Insurance Coverage to 
                Transgender persons
                </h3>
              </GridItem>
              <GridItem
                md={11}
                className={classNames(classes.mlAuto, classes.mrAuto)}
              >
                <p>
                  Any proposal from transgender is evaluated like any other proposal for Insurance coverage. 
                  </p>
                  <p>
                  The following information will be considered while underwriting the proposal for offering Insurance coverage to transgender persons. This will assist the Underwriter in 
                  fair risk assessment and unbiased underwriting decision on the proposal
                </p>
                <p><b>Past medical records (if any) of surgery or medical/hormonal treatment/ Psychological assessment and health check reports are obtained as per the disclosed medical condition.</b></p>
                <p>
                Disclosed medical conditions and the proposal would be evaluated based upon board-approved underwriting guidelines for making an objective decision to offer Insurance Coverage. 
                </p>
              </GridItem>
            
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

UnderwritingPhilosophy.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
};

export default withStyles(underWritingStyle)(UnderwritingPhilosophy);

export const underWritingPhilosophyQuery = graphql`
  query UnderwritingPhilosophy {
    allStrapiImages(filter: { alt: { eq: "Underwriting Philosophy" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
